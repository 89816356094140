<template>
    <div id="sparseDenseSetting">
        <jy-query ref="form" :model="form" label-width="72px">
            <jy-query-item prop="routeName" label="线路组织:">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('form')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="addUdate()">新增</el-button>
                    <el-button type="primary" @click="batchdelete">批量删除</el-button>
                    <!-- <el-button type="primary" @click="onexport">导出</el-button>
                    <el-button type="primary" @click="onimport">导入</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="multipleTable" tooltip-effect="dark" @select-all="selectallchange" @select="selectchange" :data="dataList" header-cell-class-name="bg_color" border style="width: 100%">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column type="selection" width="40"></jy-table-column>
            <jy-table-column prop="routeName" label="线路名称" min-width="120"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
            <jy-table-column prop="timeSegLabel" label="时段名称" min-width="120"></jy-table-column>
            <jy-table-column prop="beginTime" label="开始时间" min-width="120"></jy-table-column>
            <jy-table-column prop="endTime" label="结束时间" min-width="120"></jy-table-column>
            <jy-table-column prop="dateTypeLabel" label="日期类型" min-width="120">
                <template slot-scope="scope">{{scope.row.dateTypeLabel|dateTypeFilter}}</template>
            </jy-table-column>
            <!-- <jy-table-column prop="dirLabel" label="方向" min-width="120">
                    <template slot-scope="scope">{{scope.row.dirLabel|dirFilter}}</template>
                </jy-table-column>-->
            <jy-table-column prop="remark" label="备注" class-name="align_left" min-width="200"></jy-table-column>
            <jy-table-column prop="createTime" label="创建日期" min-width="120"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <add-update-sparsedense ref="addUpdateSparsedense" @updateList="getList"></add-update-sparsedense>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import addUpdateSparsedense from '@/components/pages/admin/setting/sparseDenseSetting/addUpdateSparsedense'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'

export default {
    data() {
        return {
            siteLineList: [],
            props: {
                children: 'children',
                label: 'text'
            },
            form: {
                routeId: '',
                routeName: ''
            },
            batchdeleteList: [],
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            operateList: []
        }
    },
    filters: {
        dateTypeFilter(val) {
            if (val === 1) {
                return '全天'
            } else if (val === 2) {
                return '工作日'
            } else if (val === 3) {
                return '周末'
            } else {
                return val
            }
        },
        dirFilter(val) {
            if (val === 1) {
                return '全部'
            } else if (val === 2) {
                return '上行'
            } else if (val === 3) {
                return '下行'
            } else {
                return val
            }
        }
    },
    watch: {
        'form.routeName': function(val, oldVal) {
            if (val != oldVal) {
                if (this.form.routeName == '') {
                    this.form.routeId = ''
                }
                this.getList()
            }
        }
    },

    components: {
        addUpdateSparsedense,
        institutionsTree
    },

    computed: {},

    activated() {},

    created() {
        this.getList()
        this.setOperateList()
    },

    methods: {
        setOperateList() {
            let l = [{
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.addUdate
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete
                },
                {
                    name: '查看',
                    icon: 'el-icon-view',
                    fun: this.handlecheck
                }
            ]
            this.operateList = l
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        addInstitutions(data) {
            console.log(data)
            this.form.routeId = data.id
            this.form.routeName = data.text
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            console.log(option)
            let url = '/psvehilcedensityset/queryPageList'
            this.$http.post(url, option).then(({ detail }) => {
                // console.log(detail)
                this.dataList = detail.list
                this.total = detail.total
                this.batchdeleteList = []
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 勾选数据
        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        addUdate(option) {
            if (option) {
                option.sparsecheck_1 = true
                option.densecheck_2 = true
                this.$refs.addUpdateSparsedense.init(option, 'update')
            } else {
                //新增
                // if (this.form.routeName) {
                this.form.sparsecheck_1 = true
                this.form.densecheck_2 = true
                this.$refs.addUpdateSparsedense.init(this.form, 'add')
                // } else {
                //   this.$alert('请先选择线路！', '提示', {
                //     type: 'warning'
                //   })
                // }
            }
        },
        handlecheck(option) {
            this.$refs.addUpdateSparsedense.init(option, 'view')
        },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该文件?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    let option = [row]
                    this.deleteRecord(option)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        // 批量删除
        batchdelete() {
            if (this.batchdeleteList.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请先选取数据'
                })
            } else {
                this.$confirm('是否删除所选中的记录?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(() => {
                        let option = this.batchdeleteList
                        this.deleteRecord(option)
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        })
                    })
            }
        },
        deleteRecord(supplierIdAry) {
            this.$http
                .post('/psvehilcedensityset/delete', supplierIdAry)
                .then(data => {
                    if (data.code == '0') {
                        this.$message.success(data.msg)
                        this.getList()
                    } else {
                        this.$message.error(data.msg)
                    }
                })
        },
        onexport() {
            console.log('onexport')
        },
        onimport() {
            console.log('onimport')
        }
    }
}

</script>
<style lang='scss' scoped>
</style>
